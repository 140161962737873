<template>
	<div id="app" v-if="isRouterAlive">
		<b-modal v-model="urgent" scrollable :header-class="['border-0', 'pt-3', 'pb-1']" :body-class="['pl-0', 'pr-2', 'py-1']" :footer-class="['p-0', 'border-0']" centered>
			<template #modal-header="{close}">
				<div class="position-relative w-100">
					<h5 class="text-deepgold m-0 text-center">[更新公告]</h5>
				</div>
			</template>
			<template>
				<div class="pt-2 px-5 pb-5">
					<p class="mb-4 text-left">線上課程於2023/1/11 13:00恢復使用，請重新登入帳號，即可進入課程...</p>
					<p class="mb-0 text-left" style="font-size: 12px;">※1/10~1/11期間被扣除尚未使用之時數，系統將歸回時數</p>
					<p class="mb-0 text-left" style="font-size: 12px;">※如有發生異常操作情況，請洽客服專線0227338118</p>
				</div>
				<div style="font-size: 12px;">馬跡中心 敬上</div>
				<div style="font-size: 12px;">客服時段 週一至週五 10:00~18:00</div>
			</template>
			<template #modal-footer="{close}">
				<div class="mx-auto pt-3">
					<button class="btn btn-danger" @click="close()">關閉</button>
				</div>
			</template>
		</b-modal>
		<b-modal v-model="useSafari" scrollable :header-class="['border-0', 'pt-3', 'pb-1']" :body-class="['pl-0', 'pr-2', 'py-1']" :footer-class="['p-0', 'border-0']" centered>
			<template #modal-header="{close}">
				<div class="position-relative w-100">
					<h5 class="text-deepgold m-0 text-center">系統警告</h5>
				</div>
			</template>
			<template>
				<div class="pt-2 px-5 pb-5">
					<p>您目前使用的瀏覽器為「Safari」，會有影片無法正常顯示或無法讀取到影片的現象。</p>
					<p>建議使用 「<img src="@/assets/images/Chrome.png" style="width: 20px; height: 20px;" />Google Chrome」，為您帶來良好的觀賞環境</p>
				</div>
				<!-- <div class="pt-2 px-5 pb-5">
					<p class="mb-4 text-left">線上課程於2023/1/11 13:00恢復使用，請重新登入帳號，即可進入課程...</p>
					<p class="mb-0 text-left" style="font-size:12px">※1/10~1/11期間被扣除尚未使用之時數，系統將歸回時數</p>
					<p class="mb-0 text-left" style="font-size:12px">※如有發生異常操作情況，請洽客服專線0227338118</p>
				</div> -->
				<div style="font-size: 12px;">馬跡中心 敬上</div>
				<div style="font-size: 12px;">客服時段 週一至週五 10:00~18:00</div>
			</template>
			<template #modal-footer="{close}">
				<div class="mx-auto pt-3">
					<button class="btn btn-danger" @click="close()">關閉</button>
				</div>
			</template>
		</b-modal>

		<router-view name="titlebar" class="w-100" v-if="showHeaderMenu"></router-view>
		<router-view name="mainpage"></router-view>
		<router-view id="footer" name="footer"></router-view>
		<div class="w-100 bg-light border-top border-white" style="bottom: 0;">
			<div class="col-12 d-flex justify-content-center p-3">
				<small>馬跡庫比有限公司 版權所有 © 2023 Mageecube Company All Rights Reserved.</small>
			</div>
		</div>
		<loading :show="openLoading"></loading>
	</div>
</template>

<script>
	import store from "@/store";
	import loading from "@/components/loadingpage";

	export default {
		data() {
			return {
				showHeaderMenu: "true",
				isRouterAlive: true,
				urgent: false,
				useSafari: false,
			};
		},
		watch: {
			$route(data) {
				// console.log(data.name);
				if (data.name == "thirdpage") {
					this.showHeaderMenu = false;
				} else {
					this.showHeaderMenu = true;
				}
			},
		},
		provide() {
			return {
				reload: this.reload,
			};
		},
		computed: {
			openLoading: {
				get() {
					return this.$store.state.Public.loading;
				},
			},
		},
		mounted() {
			const ua = navigator.userAgent;
			const isChrome = /Chrome/.test(ua) || /CriOS/.test(ua);
			const isSafari = /Safari/.test(ua) && !isChrome;
			const isLine = /Line/.test(ua);
			if (isSafari) {
				this.useSafari = true;
			} else if (isLine) {
				window.location.href = window.location.origin + "?openExternalBrowser=1";
			}
			var onConnectionChange = () => {
				const { rtt, downlink, effectiveType, saveData } = navigator.connection;
				this.$store.commit("Public/setNavigator", { rtt, downlink, effectiveType, saveData });
				setTimeout(() => {
					onConnectionChange();
				}, 1000);
			};
			// navigator.connection.addEventListener("change", onConnectionChange);
			// navigator.connection.addEventListener("online", onConnectionChange);

			onConnectionChange();
		},
		methods: {
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(() => {
					this.isRouterAlive = true;
				});
			},
		},
		components: {
			loading,
		},
		beforeRouteEnter(to, from, next) {
			// console.log(to);
		},
	};
</script>
