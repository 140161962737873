import moment from "moment";
import 'moment/locale/zh-cn'
import {
  ssrCompile
} from "vue-template-compiler";

// import VueMoment from "vue-moment";

export default {
  everyhourtime(value) {
    let ss = (Math.floor(value % 60) >= 10) ? Math.floor(value % 60) : "0" + Math.floor(value % 60);
    let mm = (Math.floor(value / 60) % 60 >= 10) ? Math.floor(value / 60) % 60 : "0" + Math.floor(
      value / 60) % 60;
    let hh = (Math.floor(value / 60 / 60) >= 10) ? Math.floor(value / 60 / 60) : "" + Math.floor(
      value / 60 / 60);
    // return hh + ":" + mm + ":" + ss;

    if (mm > 0) {
      if (mm < 10) {
        let om = mm.split("");
        mm = om[om.length - 1] + "分鐘";
      } else {
        mm = mm + "分鐘";
      }
    } else {
      mm = "";
    }
    if (hh > 0) {
      if (hh < 10) {
        let oh = hh.split("");
        hh = oh[oh.length - 1] + "小時";
      } else {
        hh = hh + "小時";
      }
    } else {
      hh = "";
    }
    if (hh == 0 && mm == 0) {
      hh = "";
      mm = "0 分鐘";
    }
    return hh + mm;
  },
  timeChange(value) {
    // console.log(value/60%60);
    let mm = (Math.floor(value / 60) % 60 >= 10) ? Math.floor(value / 60) % 60 : Math.floor(
      value / 60) % 60;
    if (mm == "0NaN") {
      return "尚未觀看";
    } else if (mm == "00") {
      return "0 分鐘"
    } else {
      return mm + "分鐘";
    }
    // return hh + ":" + mm + ":" + ss;
  },
  surplustime(value) {
    let mm = (Math.floor(value % 60) >= 10) ? Math.floor(value % 60) : "0" + Math.floor(value % 60);
    let hh = (Math.floor(value / 60) % 60 >= 10) ? Math.floor(value / 60) % 60 : "0" + Math.floor(
      value / 60) % 60;
    if (mm > 0) {
      if (mm < 10) {
        let om = mm.split("");
        mm = om[om.length - 1] + "分鐘";
      } else {
        mm = mm + "分鐘";
      }
    } else {
      mm = "";
    }
    if (hh > 0) {
      if (hh < 10) {
        let oh = hh.split("");
        hh = oh[oh.length - 1] + "小時";
      } else {
        hh = hh + "小時";
      }
    } else {
      hh = "";
    }
    if (hh == 0 && mm == 0) {
      hh = "";
      mm = "0 分鐘";
    }
    return hh + mm;
    // return Math.floor(value / 60 / 60) > 0 ? + "小時" : "";
  },
  videoProgress(value, text) {
    let ss = (Math.floor(value % 60) >= 10) ? Math.floor(value % 60) : "0" + Math.floor(value % 60);
    let mm = (Math.floor(value / 60) % 60 >= 10) ? Math.floor(value / 60) % 60 : "0" + Math.floor(
      value / 60) % 60;
    let hh = (Math.floor(value / 60 / 60) >= 10) ? Math.floor(value / 60 / 60) : "" + Math.floor(
      value / 60 / 60);
    if (mm > 0) {
        mm = mm;
    } else {
      mm = "00";
    }
    if (hh > 0) {
      hh = hh;
    } else {
      hh = "00";
    }
    if (hh == 0 && mm == 0) {
      hh = "00";
      mm = "00";
    }
    if(text=='CH'){
      if (hh > 0) {
        if (hh < 10) {
          let oh = hh.split("");
          hh = oh[oh.length - 1] + " 小時 ";
        } else {
          hh = hh + " 小時";
        }
      } else {
        hh = "";
      }
      if (mm > 0) {
        if (mm < 10) {
          let om = mm.split("");
          mm = om[om.length - 1] + " 分鐘 ";
          ss = ""
        } else {
          mm = mm + " 分鐘 ";
          ss = ""
        }
      } else {
        mm = "";
        if (ss > 0) {
          if (ss < 10) {
            let os = ss.split("");
            ss = os[os.length - 1] + " 秒";
          } else {
            ss = ss + " 秒";
          }
        } else {
          ss = "0 秒";
        }
      }
      
      return hh + mm + ss;
    }else{
      return hh +":" + mm + ":" + ss;
    }
  }
}
