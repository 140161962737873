import sup from './support';

export default {
  logplay(buyuid, videouid, type, logtime, proid) {
    return sup.get("videoLog/logplay", {
      buyuid: buyuid,
      videouid: videouid,
      type: type,
      logtime: logtime,
      proid: proid
    },{},"",false);
  },
  logpause(buyuid, videouid, type, logtime, proid) {
    return sup.get("videoLog/logpause", {
      buyuid: buyuid,
      videouid: videouid,
      type: type,
      logtime: logtime,
      proid: proid
    },{},"",false);
  },
  logwatchtimea(buyuid, videouid, type, logtime, proid) {
    return sup.get("videoLog/logwatchtimea", {
      buyuid: buyuid,
      videouid: videouid,
      type: type,
      logtime: logtime,
      proid: proid
    },{},"",false);
  },
  videosaveduration(element, duration, proid) {
    return sup.get("videoLog/videosaveduration", {
      element: element,
      duration: duration,
      proid: proid
    },{},"",false);
  },
}
