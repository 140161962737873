import sup from './support';

export default {
  login(account, password, vehicle) {
    return sup.post("login", {
      account: account,
      password: password,
      vehicle: vehicle
    });
  },
  iwatchlogin() { 
    return sup.get("iwatchlogin");
  },
  getinformation() {
    return sup.get("Member/getinformation");
  },
  checkmember() {
    return sup.get("Login/getmember");
  }
}
