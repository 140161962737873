import axios from 'axios';
import store from '@/store';
import CryptoJS from 'crypto-js';
import base64 from "crypto-js/enc-base64";
const cancelrequest = {};
const listspinner = {};
const encrypt = (text) => {
  if (typeof (text) != "string") {
    text = JSON.stringify(text);
  }
  var key = base64.parse(process.env.VUE_APP_KEY); //为了避免补位，直接用16位的秘钥
  var iv = CryptoJS.enc.Latin1.parse(process.env.VUE_APP_IV); //16位初始向量
  var encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });
  return btoa(JSON.stringify({
    value: encrypted.toString(),
    mac: CryptoJS.SHA512(iv).toString()
  }));
};
export default {

  httpctrl(method, url, data, config = {}, cancelname = "", spinner = true) {
    const tokenSet = sessionStorage.token || localStorage.token;
    var CancelToken = axios.CancelToken;
    config = {
      'headers': {
        'Authorization': "Bearer " + tokenSet,
        'company': process.env.VUE_APP_company
      }
    }
    let responsedata = {};
    if (method == "get") {
      responsedata = {
        params: data
      };
    } else {
      responsedata={data:data};
      // responsedata = { data: { data: encrypt(data) } };
    }

    if (cancelname && cancelrequest[cancelname]) {
      cancelrequest[cancelname]();
      delete listspinner[url];
      if (Object.keys(listspinner).length == 0) {
        store.dispatch("Public/openLoading", url)
      }
    }

    if (spinner) {
      store.dispatch("Public/openLoading", url)
      listspinner[url] = 1;
    };
    const ajax = axios.request({
      baseURL: process.env.VUE_APP_APILOCAL,
      url: url,
      method: method,
      cancelToken: new CancelToken((c) => {
        // executor 函数接收一个 cancel 函数作为参数
        (cancelname) ? cancelrequest[cancelname] = c: "";
      }),
      ...responsedata,
      ...config,

    });
    ajax.then((data) => {
      if (spinner) {
        delete listspinner[url];
        if (Object.keys(listspinner).length == 0) {
          store.dispatch("Public/closeLoading", url)
        }

      };
    }).catch((error) => {
      if (error.response.status == 401) {
        localStorage["member"] = "";
        localStorage["token"] = "";
        // location.href = "/";
        // store.dispatch("Public/showToast", {
        //   title:"您的帳號認證已過期",
        //   method:"danger",
        //   text:"您可能在其他裝置上登入，請再登入一次",
        // })
      }
      delete listspinner[url];
      if (Object.keys(listspinner).length == 0) {
        store.dispatch("Public/closeLoading", url)
      }
    });
    return ajax;
  },
  post(url, data, config, cancelname = "", spinner = true) {
    return this.httpctrl("post", url, data, config, cancelname, spinner);
  },
  get(url, data, config, cancelname = "", spinner = true) {
    return this.httpctrl("get", url, data, config, cancelname, spinner);
  },
  setlistspinner(ckString){
    listspinner[ckString] = 1 ;
  },
  removelistspinner(ckString){
   delete listspinner[ckString] ;
  }
}
