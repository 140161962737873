<template>
  <div class="w-100 h-100 position-fixed d-flex align-items-center justify-content-center"
    style='background:rgba(255,255,255,0.9);z-index:99999;top:0;left:0' v-if='show'>
    <div class='d-flex flex-column' >
      <div class='d-block text-center position-relative'>
        <div class="spinner-border text-danger d-block position-relative" style="width: 5rem; height: 5rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="position-absolute" style="left:12px;top:10px;">
          <img src="@/img/LOGO_sm.png" class=" mr-2" alt
            style="max-height: 45px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import store from "@/store";
  export default {
    name: "loadingpage",
    data() {
      return {}
    },
    props: {
      show: {
        type: Boolean,
        default: true
      }
    },
    watch:{
      show(data){
        if(data==true){
          if(store.state.member.login){
            store.dispatch("member/checkmember").catch((err)=>{
              if(err&&store.state.member.login==false){
                  this.$router.go(0);
              }else{
                // console.log(err)
              }
            });
          }
          return data
        }
      }
    }
  }

</script>
<style lang="scss">

  @import '../style/_variables';

  @keyframes myfirst {
    0% {
      background: map-get($theme-colors, danger);
    }

    25% {
      background: map-get($theme-colors, warning);

    }

    50% {
      background: $magee-blue;

    }

    75% {
      background: map-get($theme-colors, success);

    }

    100% {
      background: map-get($theme-colors, danger);
    }
  }

  @-webkit-keyframes myfirst {
    0% {
      background: map-get($theme-colors, danger);
    }

    25% {
      background: map-get($theme-colors, warning);

    }

    50% {
      background: $magee-blue;

    }

    75% {
      background: map-get($theme-colors, success);

    }

    100% {
      background: map-get($theme-colors, danger);
    }
  }

  .loading {
    .box {
      animation: myfirst 5s infinite;
      -webkit-animation: myfirst 5s infinite;

    }
  }

</style>
