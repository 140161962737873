import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';

const moment = require('moment');
require('moment/locale/zh-tw');

Vue.use(require('vue-moment'), {
  moment
});

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import router from './router';
import store from './store';
import './service/filter/index';

import '@/style/style.scss';
import '@/style/index.scss';

import './plugins/fontawesome';
import VScrollLock from 'v-scroll-lock'
import  VueScrollTo from 'vue-scrollto';
import screenfull from "screenfull" 
Vue.use(VueScrollTo)
Vue.use(VScrollLock)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
