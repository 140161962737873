import Vue from 'vue';
import Vuex from 'vuex';
import member from './modules/member';
import course from './modules/course';
import videodata from './modules/videodata';
import Public from './modules/Public';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    member: member,
    course: course,
    videodata: videodata,
    Public: Public
  },
});
