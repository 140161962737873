import sup from './support';
import {
  LayoutPlugin
} from 'bootstrap-vue';

export default {
  product_class(searchmethod, date) {
    return sup.get("Product/MainProlist", {
      searchmethod: searchmethod
    });
  },
  subprolist(buylist, pro) {
    return sup.get("Product/sublist", {
      buylist: buylist,
      pro: pro
    });
  },
  getproElement(watchuid, prouid) {
    return sup.get("getproElement", {
      watchuid: watchuid,
      prouid: prouid
    });
  },
  getvideo(buyuid, uid, mainuid, proid) {
    return sup.get("Video/getvideo", {
      buyuid: buyuid,
      uid: uid,
      mainuid: mainuid,
      proid: proid
    });
  },
  deductTime(buyuid, uid, mainuid, proid) {
    return sup.post("Video/deductTime", {
      buyuid: buyuid,
      uid: uid,
      mainuid: mainuid,
      proid: proid
    });
  },
  getmember() {
    return sup.get("Login/getmember", {
      func: "member",
      account: localStorage.token
    }, {
      headers: {
        func: "member"
      }
    });
  },
}
